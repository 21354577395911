<template>
  <b-overlay :show="loading">
    <admin-form @loading="onLoading" @loaded="onLoaded" />
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import AdminForm from "@v@/components/forms/AdminForm"
  // import {debugConsole} from "@c@/helpers/helpers";
  export default {
    name: "index",
    components: {Portlet, AdminForm},
    mounted() {},
    data(){
      return {
        loading: false,
      };
    },
    methods: {
      onLoading(){
        this.loading = true
      },
      onLoaded(){
        this.loading = false
      },
    }
  }
</script>

<style scoped>

</style>
